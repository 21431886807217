import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics";
import { useAuth } from "../AuthContext"
import { analytics } from "../firebaseApp"

export default function UpdateProfile() {
  const emailRef = useRef()
  const nameRef = useRef()
  const { currentUser, changeProfile } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useNavigate()


  logEvent(analytics, `${currentUser.email}: UpdateProfile`);

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    setError("")

    try {
      if ((nameRef.current.value.length > 1) 
      && (nameRef.current.value !== currentUser.displayName) ){
        const profile = {
          displayName: nameRef.current.value
        }
        changeProfile(profile)
      }
      history("/")
    } catch ( e ){
      console.log(e)
      setError("Failed to update account")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Update Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="email">
              <Form.Label column sm="2">Email:</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  ref={emailRef}
                  plaintext
                  readOnly
                  defaultValue={currentUser.email}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column sm="2">Name:</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  ref={nameRef}
                  defaultValue={currentUser.displayName}
                  placeholder="Name"
                />
              </Col>
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}