import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function UpdatePassword() {
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, changePassword } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useNavigate()

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    setLoading(true)
    setError("")

    try {
      if ((passwordRef.current.value.length > 5) 
      && (passwordRef.current.value.length===passwordConfirmRef.current.value.length) ){
        changePassword(passwordRef.current.value)
        history("/")
      }
    } catch ( e ){
      console.log(e)
      setError("Failed to update account")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Update Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="email">
              <Form.Label column sm="2">Email:</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  plaintext
                  readOnly
                  defaultValue={currentUser.email}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column sm="2">Name:</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  plaintext
                  readOnly
                  defaultValue={currentUser.displayName}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password">
              <Form.Label column sm="2">Password:</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Leave blank to keep the same"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password-confirm">
              <Form.Label column sm="2">Password Confirmation</Form.Label>
              <Col sm="10">              
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Leave blank to keep the same"
                />
              </Col>
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}