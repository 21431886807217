import React, { useContext, useState, useEffect } from "react"
import { firebaseApp } from "./firebaseApp"
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  updateProfile,
  sendPasswordResetEmail,
  sendEmailVerification,
  reauthenticateWithCredential,
} from "firebase/auth";


const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

/**
 * 認証プロバイダ
 * @param {*} param0 
 * @returns 
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  /**
   * サインアップ
   * @param {*} email E-mail
   * @param {*} password パスワード
   * @returns 
   */
  function signUp(email, password) {
    const auth = firebaseApp.auth();
    return createUserWithEmailAndPassword(auth, email, password)
  }

  /**
   * ログイン
   * @param {*} email E-mail
   * @param {*} password パスワード
   * @returns 
   */
  function login(email, password) {
    const auth = firebaseApp.auth();
    return signInWithEmailAndPassword(auth, email, password);
  }

  /**
   * ログアウト
   * @returns 
   */
  function logout() {
    const auth = firebaseApp.auth();
    return signOut(auth)
  }

  /**
   * ユーザーに確認メールを送信する
   * @returns 
   */
  function verifyEmail() {
    const auth = firebaseApp.auth();
    return sendEmailVerification(auth)
  }

  /**
   * パスワードの再設定メールを送信する
   * @param {*} email E-mail
   * @returns 
   */
   function resetPassword(email) {
    const auth = firebaseApp.auth();
    return sendPasswordResetEmail(auth, email)
  }

  /**
   * E-mailの変更
   * @param {*} email E-mail
   * @returns 
   */
  function changeEmail(email) {
    return updateEmail(currentUser, email)
  }

  /**
   * パスワードの変更
   * @param {*} password パスワード
   * @returns 
   */
  function changePassword(password) {
    return updatePassword(currentUser, password)
  }

  /**
   * プロフィールの変更
   * @param {*} profile プロフィール
   * @returns 
   */
   function changeProfile(profile) {
    return updateProfile(currentUser, profile)
  }
  
  useEffect(() => {
    //console.log(firebaseApp)
    const auth = firebaseApp.auth();
    console.log(auth)
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signUp,
    logout,
    resetPassword,
    changeEmail,
    changePassword,
    changeProfile,
    verifyEmail
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}