import React, { useState } from "react";
import { Card, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useAuth } from "../AuthContext";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      console.log(currentUser);
      await logout();
      // history.push("/login")
      history("/login");
    } catch (e) {
      setError("Failed to log out");
    }
  }

  console.log("currentUser");
  console.log(currentUser);

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="email">
              <Form.Label column sm="2">
                Email:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  plaintext
                  readOnly
                  defaultValue={currentUser.email}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password-confirm">
              <Form.Label column sm="2">
                displayName:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={currentUser.displayName}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password-confirm">
              <Form.Label column sm="2">
                creationTime:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={currentUser.metadata.creationTime}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="password-confirm">
              <Form.Label column sm="2">
                lastSignInTime:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={currentUser.metadata.lastSignInTime}
                />
              </Col>
            </Form.Group>
          </Form>
          <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>
          <Link to="/update-password" className="btn btn-primary w-100 mt-3">
            Update Password
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  );
};

export default Home;
